.HomeMenu {
    width: 100%;
    height: 100%;
}

.HomeMenuCommunication {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 700;
    text-shadow: 0 0 4px var(--main-green-color);
    color: var(--main-green-color);
    background-color: black;
}

.HomeMenuContactsLinks {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    z-index: 1;
}

.HomeMenuContactsLinks > a {
    cursor: pointer;
}

.HomeMenuContactsLink {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-radius: 50%;
    box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, .471);
    cursor: pointer;
    width: 36px;
    height: 36px;
    background-color: rgb(0, 96, 180);
    box-sizing: border-box;
    transition: 0.2s;
}

.HomeMenuContactsLink:hover {
    background-color: black!important;
}

.HomeMenuContactsLink svg {
    display: block;
}