#root {
    width: 100vw;
    height: 100vh;
}

.Loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

input {
    all: unset;
    box-sizing: border-box;
    background-color: var(--input-color);
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
    box-shadow: 0 0 0 1px var(--input-border);
}

input:focus {
    box-shadow: 0 0 0 2px var(--input-border-active);
}

button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-default-color);
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    width: max-content;
    padding: 5px 20px;
    font-weight: 600;
    outline: none;
    border: none;
    box-sizing: border-box;
    color: var(--main_color);
}

@media (hover: hover) {
    button:hover {
        transform: scale(0.99);
        box-shadow: 0 0 0 1px var(--input-border);
        opacity: 0.9;
    }
}

button:active {
    transform: scale(0.985);
    opacity: 0.7;
}
