.Shop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.ShopWindow {
    background-color: var(--background_menu);
    width: 90%;
    max-width: 420px;
    border-radius: 8px;
    overflow: hidden;
}

.ShopWindowHeader {
    margin: 0;
    font-size: 16px;
    text-align: center;
    border-bottom: 0.5px solid var(--separator_menu);
    opacity: 0.8;
    padding: 10px;
    font-weight: 800;
}

.ShopWindowInfo {
    padding: 10px 20px;
    padding-top: 5px;
}

.ShopWindowInfo > p {
    font-size: 15px;
}

.ShopWindowInfo > p > span {
    color: var(--sky_color);
}

.ShopWindowInfo > p > a {
    color: var(--sky_color);
}


.ShopWindowForm {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    text-transform: uppercase;

    background-color: var(--sky_color);
    background-image: linear-gradient(135deg, #7abbe7 0%, #9599E2 100%);
    transition: 0.2s;
}

@media (hover: hover) {
    .ShopWindowForm:hover {
        opacity: 0.8;
    }
}

.ShopWindowForm:active {
    opacity: 0.6;
}