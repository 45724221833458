@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

:root {
    --main-green-color: #00c086;

    --background_color: rgb(10, 10, 10);
    --background_menu: rgb(40, 38, 38);
    --background-card: #0d1116;

    --separator_menu: rgb(215, 197, 197);
    --main_color: rgb(237, 237, 237);
    --sky_color: #8BC6EC;

    --input-color: #20354e;
    --input-border: #225798;
    --input-border-active: #207aaa;

    --button-default-color: #207aaa;

    --black-vk: #2788de;
    --blue-vk: #005bab;
    --inst: #C13584;
    --tg: #259cd7;
    --git: #595959;
    
    --grey_button_color: radial-gradient(circle farthest-corner at 5.6% 54.5%,  rgba(47,71,79,1) 0%, rgb(99, 114, 119) 83.6%);
}

body {
    user-select: none;
    -webkit-user-select: none;
    font-family: 'IBM Plex Mono', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background: var(--background_color);
    color: var(--main_color);
}
